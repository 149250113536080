<template>
  <div>
    <Header />
    <Search />
    <div class="buy">
      <div class="succeed">
        <img src="../../assets/order/succeed.png" alt="" />
      </div>
      <div class="goumai">购买成功</div>
      <div class="chuli">我们会尽快为您处理</div>
<!--      <div class="dingdan ding">-->
<!--        订单号：<span class="spen">12663844968550</span>-->
<!--      </div>-->
<!--      <div class="dingdan">-->
<!--        收货地址：<span class="spen"-->
<!--          >山东 济南市 历城区 致远街道 中山东路中联花园B区 李先生-->
<!--          17897876211</span-->
<!--        >-->
<!--      </div>-->
<!--      <div class="dingdan">-->
<!--        实付款：<span class="spen">12663844968550</span>-->
<!--      </div>-->
      <div class="xiang">
        <div class="xiang_L" @click="dingdan">查看订单详情</div>
        <div class="xiang_R" @click="home">随便逛逛</div>
      </div>

      <div class="tixing">
        <img src="../../assets/order/tixing.png" alt="" />
        <div class="wen">
          安全提示：标物平台及销售商不会以订单异常、系统升级为由，要求您点击任何链接进行退款。
        </div>
      </div>
    </div>
    <!-- <Baoyou /> -->
    <div class="kongbai"></div>
    <Foot />
  </div>
</template>
<script>
import Header from '@/components/header.vue';
import Foot from '@/components/foot.vue';
import Search from '@/components/order/buy_succeed/search.vue';
import Baoyou from '@/components/order/baoyou.vue';
export default {
  components: {
    Header,
    Foot,
    Search,
    Baoyou,
  },
  methods: {
    home() {
      this.$router.push('/');
    },
    dingdan() {
      this.$router.push({
        name: 'Dingdan',
      });
    },
  },
};
</script>
<style lang="scss" scoped>
.buy {
  width: 675px;
  border: 2px solid #f1f1f1;
  margin: 57px auto;
  padding-left: 85px;
  padding-bottom: 30px;
  .succeed {
    width: 43px;
    height: 43px;
    margin-top: 29px;
    img {
      width: 100%;
      height: 100%;
    }
  }
  .goumai {
    font-size: 24px;
    color: #fd902b;
    margin-top: 17px;
  }
  .chuli {
    font-size: 24px;
    color: #fd902b;
    margin-top: 10px;
  }

  .dingdan {
    font-size: 14px;
    color: #777;
    margin-top: 10px;
    .spen {
      color: #282828;
    }
  }
  .ding {
    margin-top: 23px;
  }
  .xiang {
    margin-top: 22px;
    color: #2558f2;
    display: flex;
    font-size: 14px;
    .xiang_L {
      cursor: pointer;
    }
    .xiang_R {
      margin-left: 30px;
      cursor: pointer;
    }
  }
  .tixing {
    width: 620px;
    height: 56px;
    display: flex;
    align-items: center;
    border-top: 1px solid #e3e3e3;
    margin-top: 35px;
    padding-top: 10px;
    line-height: 30px;
    img {
      width: 16px;
      height: 16px;
    }
    .wen {
      font-size: 14px;
      color: #888;
      margin-left: 10px;
    }
  }
}
</style>
