<template>
  <div class="logo">
    <div class="logo1">
      <div class="img">
        <img src="../../../assets/order/buy_logo.png" alt="" />
        <div class="about">
          <div class="user" @click="user">个人中心</div>
          <div class="shouye" @click="home">返回首页</div>
        </div>
      </div>
      <div class="search">
        <!-- <div>bw-ZyFGTKFYQDpP</div>
        <div><img src="../../../assets/login/jiange.gif" alt="" /></div> -->
        <div class="dingdan" @click="dingdan">我的订单</div>
        <div><img src="../../../assets/login/jiange.gif" alt="" /></div>
        <div class="dingdan" @click="help">使用帮助</div>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  methods: {
    home() {
      this.$router.push({
        name: 'Home',
      });
    },
    user() {
      this.$router.push({
        name: 'user-centered',
      });
    },
    dingdan() {
      this.$router.push({
        name: 'Dingdan',
      });
    },
    help() {
      this.$router.push({
        name: 'helpCenter1',
      });
    },
  },
};
</script>
<style lang="scss" scoped>
.dingdan {
  cursor: pointer;
}
.logo {
  width: 100%;
  height: 95px;
  background: #fff;
  color: #275af3;
  border-bottom: 3px solid #f5f5f5;
  .logo1 {
    width: 1182px;
    height: 95px;
    margin: 0 auto;
    display: flex;
    align-items: center;
    justify-content: space-between;
    .img {
      width: 158px;
      height: 45px;
      display: flex;
      img {
        width: 100%;
        height: 100%;
      }
    }
    .about {
      margin-left: 20px;
      font-size: 14px;
      display: flex;
      align-items: center;

      flex-direction: column;
      .shouye {
        border: 1px solid #275af3;
        height: 25px;
        width: 80px;
        line-height: 25px;
        text-align: center;
        border-radius: 5px;
      }
    }
    .search {
      width: 208px;
      height: 30px;
      border: 2px solid #fff;
      display: flex;
      color: #343434;
      div {
        margin-left: 20px;
      }
    }
  }
}
</style>
